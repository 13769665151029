.active {
	background: #fff;
}


/*RESET*/
* {position: relative; line-height: 1.5em;}

table, table * {position: static;}

.inner {padding: 10px;}

.hundred {width: 100%;}
.ninetyFive {width: 95%;}
.ninety {width: 90%;}
.eightyFive {width: 85%;}
.eighty {width: 80%;}
.seventyEight{width: 78%;}
.seventyFive {width: 75%;}
.seventy {width: 70%;}
.sixtyFive {width: 65%;}
.sixtySeven {width: 67%;}
.sixty {width: 60%;}
.fiftyFive {width: 55%;}
.fifty {width: 50%;}
.fortyFive {width: 45%;}
.forty {width: 40%;}
.thirtyFive {width: 35%;}
.thirtyThree {width: 33%;}
.thirty {width: 30%;}
.twentyEight{width: 28%;}
.twentyFive {width: 25%;}
.twentyTwo {width: 22%;}
.twenty {width: 20%;}
.fifteen {width: 15%;}
.ten {width: 10%;}
.five {width: 5%;}

.fLeft {float: left;}
.fRight {float: right;}

.clearfix {
	content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}
ul li {
	margin-bottom: 10px;
}

.flatten {
	margin:0;
	padding:0;
	list-style-type: none;
}

/*TEXT ALIGNMENT*/
.textLeft {
	text-align: left;
}
.textCenter {
	text-align: center;
}
.textRight {
	text-align: right;
}

.big {
	font-size: 1.5em !important;
}

.small {
	font-size: 0.8em;
}
.tiny {
    font-size: 0.6em;
}
.shadow {
    box-shadow: 0px 0px 20px rgba(0,0,0,0.6);
}

.test {
	background: hotpink !important;
}

p {
	line-height: 1.5em;
}

.subHeading {
	margin-top: 0px
}
.hideMe {
	display: none;
}




html {
	min-height: 100%;
}

body {
	font: medium/normal Georgia, "Times New Roman", Times, serif;
	color: #192731;
	min-height: 100%;
	margin: 0;
	padding: 0
}

a {
	color: #7797A5;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

#header {
	/* for W3C-compliant browsers */
	background-image: linear-gradient(top, rgba(255,255,255,255) 0%, rgba(214,218,222,255) 49%, rgba(174,182,189,255) 98%);
	/* for Safari 5.03+ and Chrome 7+ */
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255,255,255,255)), color-stop(0.4915082, rgba(214,218,222,255)), color-stop(0.9828873186611601, rgba(174,182,189,255)));
	/* for Firefox 3.6+ */
	background-image: -moz-linear-gradient(top, rgba(255,255,255,255) 0%, rgba(214,218,222,255) 49%, rgba(174,182,189,255) 98%);
	/* for Opera 11.1+ */
	background-image: -o-linear-gradient(top, rgba(255,255,255,255) 0%, rgba(214,218,222,255) 49%, rgba(174,182,189,255) 98%);
	/* for IE */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#d6dade'endColorstr='#aeb6bd');
	
	min-width: 1024px;
	margin-left: auto;
	margin-right: auto;
}

#header img {
	margin: auto;
	display: block;
}

#nav {
	margin:0;
	padding: 0;
	margin-bottom: 10px;
}

#nav ul {
	width: 1024px;
	margin-left: auto;
	margin-right: auto;
}

#nav ul li {
	margin: 0px 10px;
}

#nav ul li a {
	font-size: 130%;
	padding: 10px;
	display: block;
	color: #192731;
}

#content {
	background: #fff;
}

#content-inner, .content-inner {
	padding-top: 20px;
	width: 1024px;
	margin: auto;
}

#nav li {
	list-style-type: none;
}

h2 {
/*	border-bottom: 1px solid #444;*/
}

.cleanbox {
	background: #fff;
	border: 1px solid #AEB6BD;
	border-radius: 10px;
	padding: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
}
.cleanbox h3 {
	border-bottom: 1px solid #AEB6BD;
	padding: 10px;
	margin-top: 0px;
}

.button, button, input[type=submit], a.button {
	display: inline-block;
	background: #7797A5;
	border: none;
	padding: 4px 10px;
	font-size: inherit;
	color: #000;
	border-radius: 4px;
	text-decoration: none;
	color: #fff;
	cursor: pointer;
	-webkit-appearance: none;
}

a.button:hover, .button:hover, button:hover, input[type=submit]:hover {
	background: #7797A5;
	text-decoration: none;
}

#contactUs-body .cleanbox {
	/*min-height: 300px;*/
}


#footer {
	margin-top: 100px;
	border-top: 1px solid #AEB6BD;
	text-align: center;
}